import React from "react";
import { PopupModal } from "react-calendly";
import arrowUp from "assets/arrow-up.svg";
import {PlanBookDialog} from "./planBookDialog";

export const BookDemoButton = ({ text = "Book a demo" }) => {
  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <>
      <div
        onClick={() => setOpenDialog({ data: null, dialogType: 'planBooking' })}
        className="animated-button z-10 flex justify-center items-center gap-2 cursor-pointer bg-primary-red rounded-sm px-[20px] py-[13px] text-white"
      >
        <span className="bg-white rounded-full">
          <img alt="" src={arrowUp} />
        </span>
        <span className="font-bold">{text}</span>
      </div>

      <PlanBookDialog
        openDialog={openDialog}
        onClose={() => setOpenDialog({ data: null, dialogType: '' })}
      />
    </>
  );
};
