import { useField } from "formik";
import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";

export const Checkbox = ({ label, ...props }) => {
  const [field] = useField(props.name);

  return (
    <FormControlLabel
      value="left"
      checked={field.value}
      control={
        <MuiCheckbox
          {...field}
          {...props}
          size="medium"
          sx={{
            color: "#B1B1B1",
            "& .MuiSvgIcon-root": { fontSize: 22 },
            "&.Mui-checked": {
              color: "#111",
            },
          }}
        />
      }
      label={label}
      labelPlacement="end"
    />
  );
};
