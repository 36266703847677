import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import {StyledDialog} from "./dialog";

export const ConfirmDialog = (props) => {
  return (
    <StyledDialog
      open={props.isConfirmDialogOpen}
      onClose={props.onCancel}
      width="328px"
      height="328px"
    >
      {/*<div className="flex flex-col justify-center items-center p-2">*/}
      {/*  <DialogContent>*/}
      {/*    <div className="font-bold">All your changes will be lost, are you want to exit?</div>*/}
      {/*  </DialogContent>*/}

      {/*  <div className="flex justify-between gap-4">*/}
      {/*    <PrimaryButton text="Yes" styles="!py-[8px] !px-[24px]" onClick={props.onConfirm} />*/}

      {/*    <SecondaryButton*/}
      {/*      text="Cancel"*/}
      {/*      styles="!text-main-black !py-[8px] !px-[24px]"*/}
      {/*      onClick={props.onCancel}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </StyledDialog>
  );
};

// const StyledDialog = styled(Dialog)`
//   & .MuiDialog-paper {
//     border-radius: 16px;
//     padding: 8px 8px 20px;
//     width: 328px;
//     height: 170px;
//     box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
//   }
// `;
