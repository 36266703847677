import { PageMetaSetter } from 'components'
import appPrev2 from "assets/app-prev-2.svg";
import appPrev4 from "assets/our-mission-1.svg";
import aboutUsBanner from "assets/aboutus-banner2.webp";
import whiteShapeRight from "assets/white-shape-right.svg";

export const AboutUs = () => {
  return (
    <div className="bg-light-gray">
      <PageMetaSetter 
        title="Carefree real-estate management | Locus" 
        name="description"
        content="Manage real estate, plan investments and make strategic decisions: Locus makes real-estate management accessible and easy with an all-in-one platform."
      />

      <header className="text-prrimary-black grid grid-cols-1 text-5xl lg:text-8xl pt-[78px] sm:pt-[100px] pl-[20px] sm:pl-[140px] w-fit">
        <span className="place-self-start">Carefree</span>
        <span className="place-self-center">real-estate</span>
        <span className="place-self-start">management</span>
      </header>

      <section className="flex flex-col lg:flex-row items-center justify-between gap-[72px] lg:gap-0 m-auto mt-[64px] w-full px-[45px] sm:px-[140px]">
        <div className="flex lg:justify-center sm:justify-start w-full">
          <div className="flex flex-col gap-3 w-full text-primary-gray">
            <div className="mt-4 text-2xl lg:text-4xl text-black">
              We created the best real-estate virtual assistant that supports
              investors and operators in management and investment decisions
            </div>
            <p className="w-full sm:w-[70%]">
              Locus is an initiative by young entrepreneurs in their search for
              greater efficiency and more return for real-estate managers. They
              gathered knowledge and experience from daily practice and, with
              Locus, created a platform that makes management and investment both
              effortless and more transparent.
            </p>
          </div>
        </div>
        <img
          onContextMenu={e => e.preventDefault()}
          loading="lazy"
          alt="Locus, the top real estate virtual assistant: Empowering investors and operators with management support and informed investment decisions"
          className="rounded-tr-[75px] rounded-bl-[75px] lg:rounded-tr-[150px] lg:rounded-bl-[150px] object-cover h-[366px] lg:h-[599px] max-w-full lg:w-[470px]"
          src={aboutUsBanner}
        />
      </section>

      <img alt="" className="ml-auto w-[45px] sm:w-[140px]" src={whiteShapeRight} />

      <section className="w-[80%] m-auto">
        <span className="bg-primary-white py-1 px-4 text-xs sm:text-sm w-fit rounded-2xl">
          OUR VISION
        </span>
        <div className="mt-4 w-[90%] lg:w-[60%] text-4xl lg:text-6xl">
          Successful due to the accessibility of the platform
        </div>
        <div className="w-full sm:w-fit flex flex-col sm:flex-row mt-10 gap-20">
          <p className="max-w-sm text-primary-gray">
            Managing real estate does not have to be complicated. Our straightforward and AI-driven
            platform guarantees easy access, a clear overview and convenient management of your portfolio.
            With Locus, you keep a close eye on your properties. We help you make informed decisions that
            save you time and money.
          </p>
        </div>
      </section>

      <div
        onContextMenu={e => e.preventDefault()}
        role='img' 
        alt="Simplified real estate management through technology: Present and future-focused accessibility in real estate operations."
        className="mt-[96px] sm:mt-[216px] w-[96%] aboutus-banner mr-auto relative z-20 mb-[-130px] rounded-tr-[75px] rounded-bl-[75px] sm:rounded-tr-[150px] sm:rounded-bl-[150px] px-[45px] py-[60px] sm:py-[104px] sm:px-[136px]"
      >
        <div className="text-white rounded-2xl bg-primary-gray/50 text-xs sm:text-sm py-1 px-4 w-fit">
          OUR MISSION
        </div>
        <p className="mt-8 text-4xl lg:text-6xl text-white max-w-2xl">
          Using technology we simplify real-estate management in an accessible
          manner. Now and in the future.
        </p>
        <div className="flex flex-col items-end gap-[20px] mt-[20px] w-[80%]">
          <img alt="" src={appPrev2} />
          <img alt="" src={appPrev4} />
        </div>
      </div>
    </div>
  );
};
