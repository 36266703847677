import React from "react";
import Rellax from "rellax";
import { BookDemoButton, Testimonials, Features, ForWho, PageMetaSetter } from "components";

import landingIcon1 from "assets/landing1.svg";
import landingIcon2 from "assets/landing2.svg";
import landingIcon3 from "assets/landing3.svg";
import landingIcon4 from "assets/landing4.svg";
import landingIcon5 from "assets/landing5.svg";
import landingIcon6 from "assets/landing6.svg";
import landingIcon7 from "assets/landing7.svg";
import landingIcon8 from "assets/landing8.svg";
import landingIcon9 from "assets/landing9.svg";
import LandingImage from "assets/landing_main.webp";
import phone from "assets/phone.png";
import roundedTick from "assets/rounded_tick.svg";
import Landing2 from "assets/landing2.webp";
import Landing3 from "assets/landing3.png";
import qouteIcon from "../assets/qoute-icon.svg";

export const Landing = () => {
  const rellaxRef = React.useRef(null);

  const options = {
    speed: -4,
    center: true,
    wrapper: null,
    round: true,
    vertical: true,
    horizontal: false,
  };

  React.useEffect(() => {
    new Rellax(rellaxRef.current, options);
  });


  const services = [
    {
      index: 1,
      title: 'Intuitive Dashboard',
      description:
        'Monitor property performance, track leads, and access key metrics at a glance.',
      image: landingIcon1,
    },
    {
      index: 2,
      title: 'Quick access to all contacts',
      description:
        'Store all your contacts in one place, ensuring easy access and smoother communication.',
      image: landingIcon2,
    },
    {
      index: 3,
      title: 'Effortless Property Oversight',
      description:
        "All your properties are neatly organized by name, city, address, status, type, usage, and gross (SQM). ",
      image: landingIcon3,
    },
    {
      index: 4,
      title: 'Efficient Repair Tracking',
      description:
        "Easily schedule and monitor maintenance tasks, ensuring timely repairs and tenant satisfaction.",
      image: landingIcon4,
    },
    {
      index: 5,
      title: 'Clear Listings',
      description:
        "Effortlessly manage your properties and monitor their up-to-date status.",
      image: landingIcon5,
    },
    {
      index: 6,
      title: 'Optimized lead pipeline',
      description:
        'Enhance your lead management process by tracking and streamlining lead nurturing. ',
      image: landingIcon6,
    },
    {
      index: 7,
      title: 'Real-time reports',
      description:
        "Generate reports on different aspects using real-time data from the software.",
      image: landingIcon7,
    },
    {
      index: 8,
      title: 'Efficiently closing management',
      description:
        'Eliminate physical file and spreadsheet searches by centralizing closing data in one software.',
      image: landingIcon8,
    },
    {
      index: 9,
      title: 'Easy invoicing',
      description:
        'Quickly find, organize, and monitor all your financial transactions with convenience.',
      image: landingIcon9,
    },
  ];

  const borderDesigner = (index) => {
    if (index === 1) {
      return 'lg:border-b-[1.5px] md:border-0 sm:border-b-[1.5px] xs:border-b-[1.5px] lg:border-r-[1.5px] md:border-r-0 sm:border-r-0 xs:border-r-0 border-main-dark-grey';
    } else if (index === 2) {
      return 'lg:border-b-[1.5px] md:border-0 sm:border-b-[1.5px] xs:border-b-[1.5px] border-main-dark-grey';
    } else if (index === 3) {
      return 'lg:border-l-[1.5px] md:border-0 sm:border-b-[1.5px] xs:border-b-[1.5px] lg:border-b-[1.5px] border-main-dark-grey';
    } else if (index === 4) {
      return 'lg:border-b-[1.5px] md:border-0 sm:border-b-[1.5px] xs:border-b-[1.5px] sm:border-r-0 xs:border-r-0 lg:border-r-[1.5px] md:border-r-0 sm:border-r-0 xs:border-r-0 border-main-dark-grey';
    } else if (index === 5) {
      return 'lg:border-b-[1.5px] md:border-0 sm:border-b-[1.5px] xs:border-b-[1.5px] border-main-dark-grey';
    } else if (index === 6) {
      return 'lg:border-b-[1.5px] md:border-0 sm:border-b-[1.5px] xs:border-b-[1.5px] sm:border-l-0 xs:border-l-0 lg:border-l-[1.5px] md:border-l-0 sm:border-l-0 xs:border-l-0 border-main-dark-grey';
    } else if (index === 7) {
      return 'md:border-0 sm:border-r-0 xs:border-r-0 lg:border-r-[1.5px] md:border-r-0 sm:border-r-0 xs:border-r-0 border-main-dark-grey';
    } else if (index === 8) {
      return 'md:border-0 border-main-dark-grey';
    } else if (index === 9) {
      return 'md:border-0 sm:border-l-0 xs:border-l-0 lg:border-l-[1.5px] md:border-l-0 sm:border-l-0 xs:border-l-0 border-main-dark-grey';
    }
  };

  return (
    <div className="bg-[#222325]">
      <PageMetaSetter 
        title="Locus – Easy real-estate management" 
        name="description"
        content="Locus makes managing your real-estate portfolio more efficient, clearer and professional. Discover the advantages of our platform."
      />

      <div
        ref={rellaxRef}
        className={`z-10 w-full relative h-screen flex flex-col items-start justify-start p-[40px] sm:p-[100px]`}
      >
        <img className="object-cover w-full sm:w-fit h-screen sm:h-fit absolute right-0 top-0" alt="" src={LandingImage}/>
        <h1 className="z-10 text-white text-[34px] sm:text-[42px] w-full sm:w-[55%] mb-[15px] mt-[80px] sm:mt-[40px]">
          Maximize Efficiency with AI-powered property management software
        </h1>
        <h2 className="hidden sm:block z-10 text-white text-[18px] w-full sm:w-[55%] mb-[45px]">
          Get a clear overview of your properties, communicate easily with tenants,
          keep track of your documents and collect real-time data - all-in-one platform.
        </h2>

        <BookDemoButton />
      </div>

      <div className="flex flex-col relative z-10 items-center bg-white py-[40px] sm:py-[113px] rounded-bl-[150px]">
        <div className="w-[95%] sm:w-[85%] flex flex-row-reverse sm:flex-row sm:justify-center sm:items-center sm:gap-[84px] bg-[#F6F6F6] absolute -top-36 sm:-top-48 right-0 z-20 rounded-tl-[150px] sm:rounded-br-[150px] pt-[80px] sm:pt-[80px] px-[25px] sm:px-[40px]">
          <img
            className={`w-[35%] sm:w-[25%]`}
            onContextMenu={e => e.preventDefault()}
            loading="lazy"
            alt="Owner's web application: Detailed consultation with Locus, facilitating quick activities and seamless communication with all parties involved in the rental process. Tenants have their own app for easy communication."
            src={phone}
          />
          <div className="flex flex-col">
            <div className="w-full sm:w-[80%]">
              <div className="text-[25px] lg:text-[42px]">
                Streamlined Property Care Services
              </div>
              <div className="hidden sm:block mt-[25px] text-primary-gray">
                Stay in touch with all parties involved in the rental process, keep paperwork flowing smoothly,
                streamline workflows, and ensure efficient collaboration and satisfied tenants.
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center mt-[60px] sm:mt-[280px] mb-[122px] sm:px-[120px] px-[30px]">
          <h2 className="text-start sm:text-center text-[25px] sm:text-[42px] mt-[20px]">
            Achieve more in less time with Locus
          </h2>
          <div className="text-primary-red text-start sm:text-center text-[18px] sm:text-[30px]">Why Locus?</div>
          <section className="mt-[50px] grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            {services.map((service, index) => (
              <div
                key={index}
                className={`flex flex-col gap-[20px] sm:px-[20px] py-[18px] sm:py-[50px] ${borderDesigner(
                  service.index,
                )}`}
              >
                <div className="flex justify-between items-center">
                  <div className="flex rounded-[12px] items-center justify-center bg-main-grey w-fit">
                    <img src={service.image} alt={service.title} loading="lazy" />
                  </div>
                </div>

                <div className="flex flex-col gap-[8px]" aria-label={service.description}>
                  <h3 className="text-[24px] text-main-black font-bold font-space-grotesk">
                    {service.title}
                  </h3>
                  <p className="text-[18px] leading-[28px] text-main-black">{service.description}</p>
                </div>
              </div>
            ))}
          </section>

        </div>

        <BookDemoButton />
      </div>

      <div className="flex flex-col items-center pt-[130px] pb-[60px] sm:pb-[200px]">
        <div className="flex flex-col">
          <div className="w-full">
            <div className="text-center text-3xl lg:text-5xl text-white">
              Types of real estate
            </div>
            <div className="flex flex-col sm:flex-row justify-between items-center gap-16 sm:gap-28 mt-20 sm:mt-28 text-primary-red">
              <div className="flex items-center gap-2 text-2xl sm:text-3xl">
                <img alt="" src={roundedTick}/>
                <span>Residential</span>
              </div>

              <div className="flex items-center gap-2 text-2xl sm:text-3xl">
                <img alt="" src={roundedTick}/>
                <span>Commercial</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-[40px] sm:p-[100px] bg-[#F6F6F6]">
        <div className="text-3xl sm:text-5xl text-start mb-[35px]">Who is Locus for?</div>
        <div className="flex flex-col sm:flex-row sm:items-center gap-[50px]">
          <div className="flex flex-col gap-[40px]">
            <div className="flex flex-col sm:flex-row gap-[40px] sm:gap-[70px]">
              <div className="flex flex-col w-full sm:w-1/2 gap-[13px]">
                <div className="flex items-center text-[18px] sm:text-[24px] gap-2 font-bold">
                  <div className="bg-primary-red rounded-full w-[13px] h-[13px]"></div>
                  <span>Tenants</span>
                </div>
                <div className="text-[12px] sm:text-[16px]">
                  Locus provides a direct communication channel to tenants with property managers or
                  landlords for inquiries, concerns, and updates.
                </div>
              </div>

              <div className="flex flex-col w-full sm:w-1/2 gap-[13px]">
                <div className="flex items-center text-[18px] sm:text-[24px] gap-2 font-bold">
                  <div className="bg-primary-red rounded-full w-[13px] h-[13px]"></div>
                  <span>Landlords</span>
                </div>
                <div className="text-[12px] sm:text-[16px]">
                  Locus enables landlords to automate rent collection,
                  track maintenance requests, and maintain open communication with tenants.
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-[40px] sm:gap-[70px]">
              <div className="flex flex-col w-full sm:w-1/2 gap-[13px]">
                <div className="flex items-center text-[18px] sm:text-[24px] gap-2 font-bold">
                  <div className="bg-primary-red rounded-full w-[13px] h-[13px]"></div>
                  <span>Property managers</span>
                </div>
                <div className="text-[12px] sm:text-[16px]">
                  Locus helps property managers store and organize essential property documents, maintain clear communication with tenants,
                  track and manage rent payments, efficiently handle maintenance requests, and collaborate with their team.
                </div>
              </div>

              <div className="flex flex-col w-full sm:w-1/2 gap-[13px]">
                <div className="flex items-center text-[18px] sm:text-[24px] gap-2 font-bold">
                  <div className="bg-primary-red rounded-full w-[13px] h-[13px]"></div>
                  <span>Investors</span>
                </div>
                <div className="text-[12px] sm:text-[16px]">
                  By using Locus, investors can monitor their properties at a glance. They can compare properties,
                  track financial performance, and manage their real estate portfolio efficiently.
                </div>
              </div>
            </div>
          </div>
          <img
            onContextMenu={e => e.preventDefault()}
            loading="lazy"
            alt="Using Locus, stay on top of rent payments with systematic reminders and benefit from automatic indexation for time-saving and up-to-date financial management"
            className="rounded-tr-[75px] rounded-bl-[75px] sm:rounded-tr-[150px] sm:rounded-bl-[150px] object-cover h-[367px] sm:h-[599px] w-max-full sm:w-[470px]"
            src={Landing2}
          />
        </div>
        <div className="flex justify-center mt-[60px]">
          <BookDemoButton />
        </div>
      </div>

      <div className="bg-white pt-[40px] sm:py-[120px] px-[35px] sm:px-[100px]">
        <div className="w-full text-2xl lg:text-5xl text-center mb-[50px]">
          Why our customers love Locus
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-center gap-[24px] sm:gap-[70px]">
          <div className="flex flex-row sm:flex-col items-center sm:items-start justify-evenly sm:justify-center max-w-lg gap-[14px] sm:gap-[35px]">
            <div className="flex gap-2 items-start text-[14px] sm:text-[18px]">
              <img width="24px" alt="" src={qouteIcon} />
              <div>Locus makes my paperwork much easier. I have a better overview and work more efficiently. Which is good for all parties involved</div>
            </div>

            <div className="block sm:hidden h-[73px] w-[1px] bg-[#51515199]"></div>

            <div className="sm:pl-[24px] flex items-center gap-4">
              <div className="flex flex-col">
                <span className="text-[12px] sm:text-md font-bold whitespace-nowrap">Marco R.</span>
                <span className="text-[12px] sm:text-sm text-primary-gray">Tenant</span>
              </div>
            </div>
          </div>

          <div className="hidden sm:block h-[153px] w-[1px] bg-[#51515199]"></div>

          <div className="flex flex-row sm:flex-col items-center sm:items-start justify-evenly sm:justify-center max-w-lg gap-[14px] sm:gap-[35px]">
            <div className="flex gap-2 items-start text-[14px] sm:text-[18px]">
              <img width="24px" alt="" src={qouteIcon} />
              <div>With Locus, I find all rental documents in one place. I also have a direct line of communication with the landlord, which means that questions are answered more quickly.</div>
            </div>

            <div className="block sm:hidden h-[73px] w-[1px] bg-[#51515199]"></div>

            <div className="sm:pl-[24px] flex items-center gap-4">
              <div className="flex flex-col">
                <span className="text-[12px] sm:text-md font-bold whitespace-nowrap">Fredrick D.</span>
                <span className="text-[12px] sm:text-sm text-primary-gray">Real Estate Manager</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col relative right-[35px] mt-[84px] items-center justify-center px-[16px] sm:px-0 sm:rounded-tl-[150px] sm:rounded-br-[150px] object-cover h-[467px] sm:h-[599px] w-screen sm:w-[100%] gap-[24px] bg-cover bg-top bg-[url('assets/landing3.png')]">
          <div className="text-white text-[25px] font-medium sm:text-[42px] text-center">Ready to manage your property the easy way?</div>
          <div className="text-white text-[16px] sm:text-[18px] text-center">Book a demo to see how the Locus app can help you run your properties and rentals efficiently.</div>
          <BookDemoButton />
        </div>
      </div>

      {/*<div className="bg-white sm:py-[120px] pt-[30px] sm:px-[100px]">*/}
      {/*  <div className="flex flex-col items-center justify-center px-[16px] sm:px-0 sm:rounded-tl-[150px] sm:rounded-br-[150px] object-cover h-[467px] sm:h-[599px] w-max-full sm:w-[100%] gap-[24px] bg-cover bg-center bg-[url('assets/landing3.png')]">*/}
      {/*    <div className="text-white text-[25px] font-medium sm:text-[42px] text-center">Ready to manage your property the easy way?</div>*/}
      {/*    <div className="text-white text-[16px] sm:text-[18px] text-center">Book a demo to see how the Locus app can help you run your properties and rentals efficiently.</div>*/}
      {/*    <BookDemoButton />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
