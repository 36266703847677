import React from "react";
import { Pagination, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import forWhoCommercial from "assets/for-who-commercial.webp";
import forWhoDeveloper from "assets/for-who-developer.webp";
import forWhoResedential from "assets/for-who-resedential.webp";
import forWhoTenant from "assets/for-who-tenant.webp";

export const ForWho = ({ elementRef }) => {
  const forWhoContent = [
    {
      id: 1,
      title: "Residential",
      description:
        "A clear overview of your residencies, communication with the tenants, document management,  automatic reminders, real-time data, creating to-do lists, etc. Locus gathers residential real-estate management all in one platform.",
    },
    {
      id: 2,
      title: "Commercial ",
      description:
        "With Locus, you can compare and manage all your properties and know exactly how much a property costs you. You'll save time, be more efficient, and be able to rent to a new candidate in a few clicks.",
    },
    {
      id: 3,
      title: "Project development ",
      description:
        "Locus makes collaboration with your team easier and more efficient, as you can together manage the new building in the portfolio. You can give other parties access to the tasks they're tracking themselves. Everything at a glance.\n",
    },
    {
      id: 4,
      title: "Tenant ",
      description:
        "The Locus app allows tenants to easily communicate with the landlord of their home. Tenants can easily upload documents, access their rental agreement, receive payment reminders and track the status of repairs.",
    },
    {
      id: 5,
      title: "",
      description: "",
    },
  ];

  const slideImages = [
    {src: forWhoResedential, alt: "With LOCUS you have all your properties overview, tenant communications, document uploads, automatic reminders, real-time data, and task management in one comprehensive platform."},
    {src: forWhoCommercial, alt: "Efficiently manage commercial premises with LOCUS: Cost tracking, streamlined candidate renting, easy comparison, and effective management, saving time and increasing efficiency"},
    {src: forWhoDeveloper, alt: "Streamline project management with LOCUS: Collaborate with teams, provide access to tasks, and efficiently manage new building projects in the portfolio, all at a glance"},
    {src: forWhoTenant, alt: "Tenant-friendly features on the Locus app: Easy communication with landlords, document uploads, access to rental agreements, payment reminders, and repair status tracking for a seamless renting experience"},
    null,
  ];

  const isMobile = window.innerWidth <= 768;

  const [swiper, setSwiper] = React.useState(null);
  const [slideImage, setSlideImage] = React.useState(0);
  const fileredContent = forWhoContent.filter((_) =>
    isMobile ? _.id != 5 : []
  );

  const handleSlideChangeTransitionEnd = () => {
    const currentSlide = swiper.slides[swiper.activeIndex];
    const nextSlide = swiper.slides[swiper.activeIndex + 1];

    if (nextSlide) nextSlide.style.opacity = 0.5;
    if (currentSlide) currentSlide.style.opacity = 1;

    setSlideImage(swiper.realIndex);
  };

  return (
    <div className="mt-[96px] sm:mt-[60px] w-[95%] sm:w-full m-auto">
      <div id="For who" ref={elementRef} className="hidden sm:block h-[100px]"></div>
      <div className="pl-[34px] lg:pl-[140px]">
        <span className="bg-[#FFFFFF] py-1 px-4 text-xs sm:text-sm w-fit rounded-2xl">
          FOR WHO
        </span>
      </div>

      <div className="flex flex-col sm:flex-row justify-around w-full mt-[24px]">
        <div className="flex gap-2 p-2 h-[300px] sm:h-[700px] w-fit">
          <div className="for-who-custom-pagination flex relative flex-col h-full justify-start"></div>
          <Swiper
            mousewheel={{ releaseOnEdges: true }}
            pagination={{
              el: ".for-who-custom-pagination",
              clickable: true,
            }}
            speed={1000}
            modules={[Pagination, Mousewheel]}
            onSwiper={setSwiper}
            direction="vertical"
            slidesPerView={isMobile ? 1 : 2.5}
            onSlideChangeTransitionEnd={handleSlideChangeTransitionEnd}
          >
            {fileredContent.map((_) => (
              <SwiperSlide key={_.title}>
                <div className="text-4xl">{_.title}</div>
                <div className="mt-[15px] max-w-md">{_.description}</div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="px-[45px] lg:px-0">
          <img
            onContextMenu={e => e.preventDefault()}
            loading="lazy"
            alt={slideImages[slideImage].alt}
            key={slideImage}
            className={`${
              slideImage === swiper?.realIndex && "slide-active"
            } rounded-tr-[75px] rounded-bl-[75px] sm:rounded-tr-[150px] sm:rounded-bl-[150px] object-cover h-[400px] lg:h-[599px] max-w-full sm:w-[474px]`}
            src={slideImages[slideImage].src}
          />
        </div>
      </div>
    </div>
  );
};
