import React, { useEffect } from "react";
import { FAQ, HowItWorks, ForWho, PageMetaSetter } from "components";

import brand1 from "assets/brand-1.svg";
import brand2 from "assets/brand-2.svg";
import brand3 from "assets/brand-3.svg";
import brand4 from "assets/brand-4.svg";
import locusFeature1 from "assets/locus-feature-1.svg";
import locusFeature2 from "assets/locus-feature-2.svg";
import locusFeature3 from "assets/locus-feature-3.svg";
import phone from "assets/phone.png";

import featuresRentMonitoring from "assets/features-rent-monitoring.webp";
import featuresTenantCommunication from "assets/features-tenant-communication.webp";
import whiteShapeRight from "assets/white-shape-right.svg";
import whiteShapeLeft from "assets/white-shape-left.svg";
import accounting1 from "assets/ExactOnline.svg";
import accounting2 from "assets/Twinfield.svg";
import accounting3 from "assets/ekoekhoouden.svg";
import accounting4 from "assets/snelstart.svg";
import accounting5 from "assets/yuki.svg";
import scrollIndicator from "assets/scroll-indicator.png";

export const Features = () => {
  const banks = [
    {src: brand1, alt: "ABN-ARMO"},
    {src: brand2, alt: "KBC"},
    {src: brand3, alt: "ING"},
    {src: brand4, alt: "BNP PARIBAS FORTIS"}
  ];

  const innerNav = ["Features", "How it works", "For who", "FAQ"];

  const navRef = React.useRef(null);
  const featuresRef = React.useRef(null);
  const howItWorksRef = React.useRef(null);
  const forWhoRef = React.useRef(null);
  const faqRef = React.useRef(null);

  const refs = [
    { name: "Features", ref: featuresRef },
    { name: "How it works", ref: howItWorksRef },
    { name: "For who", ref: forWhoRef },
    { name: "FAQ", ref: faqRef },
  ];

  const [isFixed, setIsFixed] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("Features");

  React.useEffect(() => {
    const handleScroll = () => {
      if (navRef.current)
        setIsFixed(window.pageYOffset >= navRef.current.offsetTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setActiveTab(entry.target.id);
        }
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      });
    refs.forEach(({ ref }) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });
    return () => {
      refs.forEach(({ ref }) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);
  
  const handleScrollTo = (el) => {
    setActiveTab(el);

    switch (el) {
      case "Features":
        featuresRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "How it works":
        howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "For who":
        forWhoRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "FAQ":
        faqRef.current.scrollIntoView({ behavior: "smooth" });
        break;
    }
  };

  return (
    <div className="bg-[#F6F6F6]">
      <PageMetaSetter 
        title="All-in-one platform for your real-estate management | Locus" 
        name="description"
        content="Property management, asset management and portfolio management all in one place with Locus. Discover the advantages and book a demo."
      />

      <h1 className="grid grid-cols-1 text-4xl lg:text-7xl pl-[20px] pr-[20px] sm:pl-[140px] sm:pr-0 pt-[100px] w-full sm:w-[70%]">
        <span className="place-self-start">One property management</span>
        <span className="place-self-end"> platform that takes care</span>
        <span className="place-self-start"> of everything</span>
      </h1>

      <div className="text-[#797979] w-full lg:w-[40%] mt-12 pl-10 sm:pl-[150px]">
        Save time and manage your real estate in
        an innovative, clear and easy manner.
        From strategic decisions down to daily activities.
      </div>

      <div 
        onContextMenu={e => e.preventDefault()}
        className="features-banner-background relative rounded-tl-[75px] rounded-br-[75px] sm:rounded-tl-[150px] sm:rounded-br-[150px] mt-[64px] pt-[42px] w-[96%] h-[311px] sm:h-[800px] ml-auto"
      >
        <img 
          alt="scroll indicator icon" src={scrollIndicator} 
          className="absolute right-12 z-30 hidden sm:block" 
        />
      </div>

      <div ref={navRef} className={`mt-[52px]`}>
        <div className="m-auto w-[50%] hidden sm:flex border-b border-[#DDDDDD]">
          {innerNav.map((_) => (
            <div
              key={_}
              onClick={() => handleScrollTo(_)}
              className={`py-[25px] w-full text-center ${
                activeTab === _ && "border-b-[2px] border-primary-red"
              } cursor-pointer`}
            >
              {_}
            </div>
          ))}
        </div>
      </div>

      <div
        className={`animated-component ${
          isFixed ? "show" : "hide"
        } bg-white w-full invisible sm:visible fixed top-0 z-50 shadow-sm`}
      >
        <div className="grid items-center ">
          <div className="flex justify-self-center w-1/2">
            {innerNav.map((_) => (
              <div
                key={_}
                onClick={() => handleScrollTo(_)}
                className={`py-[25px] w-full text-center ${
                  activeTab === _ && "border-b-[2px] border-primary-red"
                } cursor-pointer`}
              >
                {_}
              </div>
            ))}
          </div>
          {/* <div className="justify-self-end">
            <BookDemoButton />
          </div> */}
        </div>
      </div>

      <div id="Features" ref={featuresRef} className="h-2"></div>

      <div className="mt-[81px]">
        <div className="px-[45px] sm:pl-[126px]">
          <span className="bg-primary-white py-1 px-4 text-xs sm:text-sm w-fit rounded-2xl font-semibold">
            FEATURES
          </span>
          <div className="max-w-xl mt-8 text-4xl lg:text-5xl">
            We help you to manage your properties and therefore make time for
            what really matters.{" "}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between gap-[72px] lg:gap-4 m-auto mt-[64px] w-full px-[45px] sm:px-[140px]">
          <div className="text-primary-gray flex flex-col max-w-md">
            <div className="text-primary-black text-3xl sm:text-4xl">
              Keep track of your rent and indexation{" "}
            </div>
            <div className="w-full mt-[16px]">
              <div>
                Locus checks payments and sends systematic reminders when rent is not (or only partly) paid.
                Thanks to the possibility of automatic indexing, Locus saves
                time and keeps everything up to date.{" "}
              </div>
            </div>

            <div className="mt-[64px] flex items-center gap-[40px] w-full overflow-scroll sm:overflow-auto">
              {banks.map((_) => (
                <img onContextMenu={e => e.preventDefault()} key={_.alt} alt={_.alt} src={_.src} />
              ))}
            </div>
          </div>
          <img
            onContextMenu={e => e.preventDefault()}
            loading="lazy"
            alt="Using Locus, stay on top of rent payments with systematic reminders and benefit from automatic indexation for time-saving and up-to-date financial management"
            className="rounded-tr-[75px] rounded-bl-[75px] sm:rounded-tr-[150px] sm:rounded-bl-[150px] object-cover h-[367px] sm:h-[599px] w-max-full sm:w-[470px]"
            src={featuresRentMonitoring}
          />
        </div>

        <img className="ml-auto w-[45px] sm:w-[140px]" alt="" src={whiteShapeRight} />

        <div className="flex flex-col-reverse lg:flex-row items-center justify-between gap-[72px] lg:gap-4 mt-[64px] w-full px-[45px] sm:px-[140px]">
          <img
            onContextMenu={e => e.preventDefault()}
            loading="lazy"
            alt="Effortless tenant communication with Locus: Tenants report problems or damage through the app, which is automatically forwarded to the appropriate person, such as a caretaker, ensuring efficient and time-saving resolution. "
            className="rounded-tl-[75px] rounded-br-[75px] sm:rounded-tl-[150px] sm:rounded-br-[150px] object-cover h-[367px] sm:h-[599px] w-full sm:w-[470px]"
            src={featuresTenantCommunication}
          />
          <div className="text-primary-gray flex-col gap-5 max-w-md flex">
            <div className="text-primary-black text-3xl sm:text-4xl">
              Communication with Tenant
            </div>
            <div className="w-full">
              <div>
                Locus allows for easy and clear communication with tenants.
                This way, they can report problems or damages through the app.
                Since this message goes directly to the right person, such as the caretaker,
                you save a lot of time.{" "}
              </div>
            </div>
          </div>
        </div>

        <img
          onContextMenu={e => e.preventDefault()}
          className="mr-auto w-[45px] sm:w-[140px]" 
          alt="" 
          src={whiteShapeLeft} 
        />

        <div className="flex flex-col lg:flex-row items-center gap-10 mt-[32px] sm:mt-[100px] p-4">
          <div className="border-r border-r-[#d7d6d8] px-[45px] sm:px-[120px]">
            <div className="text-3xl sm:text-4xl text-primary-black max-w-md">
              Seamless accounting integrations
            </div>
            <div className="mt-[15px] max-w-md text-primary-gray">
              Locus provides a simple connection with banks worldwide and diverse accounting software.
              You decide who receives access and to which part of the rental process.
              For optimal integration.{" "}
            </div>
          </div>
          <div className="flex gap-5 items-center mt-[52px] sm:mt-0 overflow-scroll ml-auto px-[10px]">
            {[
              {src: accounting1, alt:"Exat Online"},
              {src: accounting2, alt: "Wolters Kluwer"},
              {src: accounting3, alt: "e-Boekhouden.nl"},
              {src: accounting4, alt: "snelstart"},
              {src: accounting5, alt: ""},
            ].map((_) => (
              <img onContextMenu={e => e.preventDefault()} key={_.alt} src={_.src} alt={_.alt} />
            ))}
          </div>
        </div>
      </div>

      <div className="w-[96%] rounded-tl-[150px] bg-white ml-auto mt-[96px] sm:mt-[210px]">
        <div 
          onContextMenu={e => e.preventDefault()}
          role="img" 
          aria-label="AI-driven investment approach: Locus combines market data with portfolio insights, maximizing investment potential and optimizing returns." 
          className="features-banner rounded-tl-[75px] rounded-br-[75px] sm:rounded-tl-[150px] sm:rounded-br-[150px] px-[25px] sm:px-[71px] py-[116px]"
        >
          <div className="text-white rounded-2xl bg-primary-gray/50 text-xs sm:text-sm py-1 px-4 w-fit">
            AI DRIVEN
          </div>
          <div className="mt-8 text-4xl lg:text-6xl text-white max-w-xl">
            An AI-focused approach that combines market data with the data in your portfolio.
          </div>
          {/* <div className="flex flex-col items-start sm:items-end gap-[20px] mt-[20px] mt-[62px] sm:mt-0 w-full sm:w-[80%]">
            <img src={appPrev1} />
            <img src={appPrev4} />
            <img src={appPrev5} />
          </div> */}
        </div>
      </div>

      <div className="overflow-hidden w-[96%] ml-auto bg-white pt-[96px] sm:pt-[192px] pl-[24px] sm:pl-[65px] rounded-bl-[100px] rounded-bl-[80px] sm:rounded-bl-[100px] z-20 relative mb-[-130px]">
        <div className=" text-3xl text-prrimary-black">And there is more …</div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-[70px] md:gap-0 mt-[64px] sm:mt-[96px]">
          <div className="flex flex-col gap-4">
            <img className="h-12 w-12" alt="notification icon" src={locusFeature1} />
            <div className="flex flex-col gap-1">
              <span className="font-semibold">Notifications</span>
              <span className="text-primary-gray max-w-xs">
                Your to-do list, together with all documents and
                automatic reminders, is gathered all in one place.{" "}
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <img className="h-12 w-12" alt="data management icon" src={locusFeature2} />
            <div className="flex flex-col gap-1">
              <span className="font-semibold">Data management</span>
              <span className="text-primary-gray max-w-xs">
                All data relating to your property rentals is centralised through Locus,
                providing a good overview and clear statistics.{" "}
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <img className="h-12 w-12" alt="address book icon" src={locusFeature3} />
            <div className="flex flex-col gap-1">
              <span className="font-semibold">Address book</span>
              <span className="text-primary-gray max-w-xs">
                Manage your contacts easily, too. You’ll quickly find names,
                e-mail addresses and telephone numbers.{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="border-b border-prrimary-black/10 mt-[68px] sm:mt-[192px] w-[90%]"></div>

        <div className="flex flex-col lg:flex-row gap-9 mt-[64px] sm:mt-[192px] w-[90%]">
          <div className="flex flex-col">
            <span className="bg-light-gray font-semibold py-1 px-4 text-sm w-fit rounded-2xl">
              APP
            </span>
            <div className="w-full sm:w-[80%]">
              <div className="text-3xl lg:text-5xl mt-[31px]">
                Peace of mind always at your fingertips
              </div>
              <div className="mt-[32px] text-primary-gray">
                The Locus app allows you to do quick activities and stay in
                touch with all parties involved in the rental process. Your tenants
                also have an app with which to communicate with you.
              </div>
            </div>
          </div>
          <img 
            onContextMenu={e => e.preventDefault()}
            loading="lazy" 
            alt="Owner's web application: Detailed consultation with Locus, facilitating quick activities and seamless communication with all parties involved in the rental process. Tenants have their own app for easy communication." 
            src={phone} 
          />
        </div>
      </div>

      <HowItWorks elementRef={howItWorksRef} />
      <ForWho elementRef={forWhoRef} />
      <FAQ elementRef={faqRef} />
    </div>
  );
};
