import React from "react";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import TagManager from 'react-gtm-module';

import AppRoutes from "./appRoutes/appRoutes";
import { MainHeader, Footer, MobileNavigation } from "components";
import { ScrollToTop } from "utils";
import "./App.css";

function App() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [cookiesBanner, setCookiesBanner] = React.useState(true)
  const helmetContext = {};

  ReactGA.initialize("G-RJS7CVGV9Z");

  React.useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');

    if (hasAcceptedCookies) setCookiesBanner(false);

    TagManager.initialize({ gtmId: 'GTM-W8TBGDB9' });
  }, [])

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesBanner(false);
  };

  return (
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <ScrollToTop />
        <MobileNavigation
          isMenuOpen={isMenuOpen}
          handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        />

        {!isMenuOpen && (
          <div className="bg-[#f6f6f6]">
            <MainHeader handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)} />
            
            {cookiesBanner && (
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-0 fixed bottom-0 w-full z-50 bg-[#2e2d2d] p-3">
                <div className="flex flex-col">
                  <span className="text-xl font-bold text-white">Cookies & Privacy</span>
                  <span className="text-white">
                    We are using cookies to give you a better experience. By clicking <b>Accept</b> you agree to our <a className="underline" target="_blank" href="https://legal.locus.eu/LOCUS-CookieDeclaration.pdf">Cookies Policy.</a>
                  </span>
                </div>

                <div className="flex gap-4">
                  <button onClick={acceptCookies} className="bg-[#ff0000] text-white rounded-sm p-3 font-bold">Accept All</button>
                  <button className="p-3 text-white font-bold">Reject All</button>
                </div>
              </div>
              )
            }

            <AppRoutes />
            <Footer />
          </div>
        )}
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
