import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ConfirmDialog } from './confirmDialog';

import {Dialog, styled} from '@mui/material';
import logo from 'assets/locus-logo.svg';

export const StyledDialog = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
    navigate(`${location.pathname}`, { replace: true });
    props.onClose();
  };

  const onCloseButtonClick = () => {
    if (props.isEdited) return setIsConfirmDialogOpen(true);
    navigate(`${location.pathname}`, { replace: true });
    return props.onClose();
  };

  return (
    <CustomDialog
      fullScreen={props.fullScreen}
      fullWidth={props.fullWidth}
      open={props.open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown' && props.isEdited) return setIsConfirmDialogOpen(true);
        return props.onClose();
      }}
    >
      {props.children}

      <ConfirmDialog
        isConfirmDialogOpen={isConfirmDialogOpen}
        onCancel={() => setIsConfirmDialogOpen(false)}
        onConfirm={handleConfirmDialogClose}
      />
    </CustomDialog>
  );
};

const CustomDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 100%;
    width: 100%;
    height: ${(props) => props.height ? props.height : '100%'};
    box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
  }
`;
