import React from "react";
import { Pagination, Autoplay, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import qouteIcon from "assets/qoute-icon.svg";

export const Testimonials = () => {
  const isMobile = window.innerWidth <= 768;
  const [swiper, setSwiper] = React.useState(null);

  const testimonialsContent = [
    {
      id: "1",
      content:
        "Locus makes my paperwork much easier. I have a better overview and work more efficiently. Which is good for all parties involved. ",
      name: "Frederick D.",
      role: "Real Estate Manager",
    },
    {
      id: "2",
      content:
        "Locus bundles all necessary information about all our projects. Thanks to the clear interface and handy reminders I keep control",
      name: " Koen A.",
      role: "Project Developer",
    },
    {
      id: "3",
      content:
        "With Locus, I find all rental documents in one place. I also have a direct line of communication with the landlord, which means that questions are answered more quickly.",
      name: "Marco R.",
      role: "Tenant",
    },
  ];

  return (
    <div className="block sm:flex flex-col lg:flex-row bg-white w-[96%] mt-20 lg:mt-[130px] mb-[-90px] sm:mb-[-130px] z-20 relative rounded-bl-[75px] rounded-tr-[75px] lg:rounded-bl-[150px] lg:rounded-tr-[150px] pr-1 pl-[45px] lg:pl-[120px] py-[69px] lg:py-[176px]">
      <div className="flex flex-col justify-between sm:w-[90%]">
        <div className="flex flex-col gap-4">
          <span className="bg-light-gray py-1 px-4 text-xs sm:text-sm w-fit rounded-2xl">
            TESTIMONIALS
          </span>

          <span className="mt-2 text-4xl lg:text-5xl whitespace-normal">
            Recommended by professionals
          </span>
        </div>
        <div className="hidden lg:inline pagination-container justify-end"></div>
      </div>

      <Swiper
        pagination={{
          el: ".pagination-container",
          clickable: true,
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        mousewheel={{ releaseOnEdges: true }}
        centeredSlides
        modules={[Autoplay, Pagination, Mousewheel]}
        onSwiper={setSwiper}
        className="pt-[30px]  w-full lg:w-fit"
        loop
        slidesPerView={isMobile ? 1 : 1.1}
      >
        {testimonialsContent.map((_) => (
          <SwiperSlide key={_.id}>
            <div className="flex flex-col justify-between max-w-lg min-h-[350px]">
              <div className="flex gap-2 items-start text-2xl sm:text-3xl">
                <img alt="" src={qouteIcon} />
                <div>{_.content}</div>
              </div>

              <div className="flex items-center gap-4">
                <div className="flex flex-col">
                  <span className="text-md">{_.name}</span>
                  <span className="text-sm text-primary-gray">{_.role}</span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
