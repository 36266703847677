import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import {CircularProgress, Snackbar, SnackbarContent } from '@mui/material';
import emailjs from "@emailjs/browser";
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";

import {StyledDialog} from "./dialog";
import {Steps} from "./steps";
import {Step1, Step2, Step3} from "components/planBookingDialogComponents"

import arrowUp from "assets/arrow-up.svg";
import logo from "assets/locus-logo.svg";
import image from "assets/dialog-image.png";
import close from "assets/close-icon.svg";
export const PlanBookDialog = ({ openDialog, onClose }) => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [currentStep, setCurrentStep] = React.useState(1);

  const demoForm = React.useRef(null);

  const isOpenDialog = () => {
    return openDialog?.dialogType === 'planBooking';
  };

  const handleBack = () => {
    if(currentStep !== 1) {
      console.log(currentStep)
      setCurrentStep(prev => prev - 1)
      console.log(currentStep)
    }
  }
  const isLastStep = currentStep === 3;

  const validationSchema = [
  yup.object({
    email: yup.string().email("Must be a valid email address").required("Email field is required"),
  }),
  yup.object({
    first_name: yup.string().required("First name field is required"),
    last_name: yup.string().required("Last name field is required"),
    phone_number: yup.string().required("Phone number field is required"),
    country_number: yup.string().required("Country field is required"),
    number_of_units: yup.string().required("Number of properties field is required"),
  }),
  ];

  return (
    <>
      <Snackbar
        open={isSuccess}
        onClose={() => setIsSuccess(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message="Email Sent Successfully"
        autoHideDuration={4000}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#eeffe9',
            color: '#2b9208',
            borderRadius: '12px',
          }}
          message={
            <span id="client-snackbar">
              We have received your inquiry our team will respond to you within the next 24
              hours!
            </span>
          }
        />
      </Snackbar>
      <form ref={demoForm} style={{ display: 'none' }}>
        <input type="text" name="first_name" />
        <input type="text" name="last_name" />
        <input type="email" name="email" />
        <input type="text" name="phone_number" />
        <input type="text" name="country_number" />
        <input type="text" name="number_of_units" />
      </form>
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          country_number: '',
          number_of_units: openDialog?.data || '',
        }}
        validationSchema={validationSchema[currentStep - 1]}
        onSubmit={(values, { resetForm }) => {
          if (isLastStep && isDone) {
            // Update hidden form values
            Object.keys(values).forEach(key => {
              demoForm.current[key].value = values[key];
            });

            setIsSending(true);
            emailjs
              .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EAILJS_DEMO_TEPLATE_ID,
                demoForm.current,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
              )
              .then(
                () => {
                  setIsSuccess(true);
                  setIsSending(false);
                  resetForm();
                  onClose();
                  setCurrentStep(1);
                },
                (error) => console.log(error.text)
              )
              .finally(() => setIsSending(false));
          } else {
            setCurrentStep(prev => prev + 1)
          }
        }}
      >
        {({
            errors,
            touched,
            values,
            handleChange,
            isSubmitting,
            setFieldValue,
            dirty,
            resetForm,
          }) => (
          <StyledDialog
            fullScreen
            fullWidth
            open={isOpenDialog()}
            onClose={() => {
              onClose();
              resetForm();
            }}
            isEdited={dirty}
          >
            <Form>
              <div className="flex flex-col p-[15px] sm:overflow-hidden">
                <div className="flex">
                  <div className={`flex flex-col gap-6 h-full w-full ${currentStep !== 3 ? 'sm:w-[55%]' : 'sm:w-full h-screen'} p-[24px] sm:p-[50px]`}>
                    <div className="flex items-center justify-between">
                      <img className="w-[99px]" src={logo} alt="logo" />
                      <img className="w-9 p-2 rounded-lg border hover:cursor-pointer hover:border-black" src={close} alt="close" onClick={() => {onClose();resetForm();}}/>
                    </div>
                    {currentStep !== 3 && <Steps currentStep={currentStep} />}
                    {currentStep === 1 && <Step1/>}
                    {currentStep === 2 && <Step2 numberOfUnits={openDialog?.data}/>}
                    {currentStep === 3 && <Step3 isDone={isDone} setIsDone={(bool) => setIsDone(bool)}/>}
                    <div className="flex w-full justify-center gap-7">
                      {
                        currentStep !== 1 &&
                        (
                          <button
                            type="button"
                            onClick={handleBack}
                            className={`${currentStep === 3 && 'w-full sm:w-[30%] self-center'} w-1/2 z-10 flex justify-center items-center gap-2 cursor-pointer bg-primary-gray rounded-sm px-[20px] py-[13px] text-white`}
                          >
                            <span className="font-bold">
                              Back
                            </span>
                          </button>
                        )
                      }
                      <button
                        type="submit"
                        disabled={currentStep === 3 && !isDone}
                        className={`animated-button ${currentStep === 3 && 'w-full sm:w-[30%] self-center'} w-1/2 disabled:opacity-40 disabled:cursor-default z-10 flex justify-center items-center gap-2 cursor-pointer bg-primary-red rounded-sm px-[20px] py-[13px] text-white`}
                      >
                        <span className="bg-white rounded-full">
                          <img alt="" src={arrowUp} />
                        </span>
                        <span className="font-bold">
                          {isSending ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (currentStep === 3 ? 'Done' : 'Continue')
                          }
                        </span>
                      </button>
                    </div>
                  </div>

                  {currentStep !== 3 && <img src={image} className="hidden sm:block w-[45%] h-full rounded-[16px]" alt=""/>}
                </div>
              </div>
            </Form>
          </StyledDialog>
        )}
      </Formik>
    </>
  );
};
