import {ErrorMessage, Form, useFormikContext} from "formik";
import {Input} from "../input";
import React from "react";

export const Step1 = ({ children }) => {
  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();

  return (
    <div>
      <div className="text-main-black font-bold font-space-grotesk text-[20px] sm:text-[32px]">
        Enter your email address
      </div>
      <div className="flex flex-col gap-[36px] mt-[48px] xl:max-h-fit lg:max-h-[450px] md:max-h-[450px] sm:max-h-[600px] xs:max-h-[600px] overflow-y-auto">
        <div className="flex flex-col items-center justify-between gap-[24px]">
          <div className="w-full">
            <Input
              name="email"
              placeholder="Enter your email..."
              value={values.email}
              onChange={handleChange}
              error={touched.email && errors.email}
            />
            <ErrorMessage
              name="email"
              render={msg => <div className="text-primary-red">{msg}</div>}
            />
          </div>
          <div className="text-[#9FA9BD] text-[14px]">
            By submitting, I agree that Locus <span className="text-[#10275B]">Privacy & Cookie Policy</span> and <span className="text-[#10275B]">Data Processing Policy</span> will apply to Locus’ communications.
          </div>

          <div className="underline underline-offset-1 text-[18px] text-[#292B2F]">I already have an account</div>
        </div>
      </div>
    </div>
  );
}