import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { BookDemoButton } from "components";
import { IsEmailValid } from "utils";

import { CircularProgress } from "@mui/material";
import twitter from "assets/twitter-icon.svg";
import facebook from "assets/facebook-icon.svg";
import linkedin from "assets/linkedin-icon.svg";
import locusLogo from "assets/locus-logo.svg";
import confirmIcon from "assets/confirm-icon.svg";

export const Footer = () => {
  const { pathname } = useLocation();
  const url = process.env.REACT_APP_MAILCHIMP_URL;

  const CustomForm = ({ status, message, onValidated }) => {
    const [email, setEmail] = React.useState("");

    const submit = () => {
      email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });

      status === "success" && setEmail("")
    }

    return (
      <div>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="w-full rounded-sm bg-primary-black text-white p-4 border border-primary-gray focus:outline-none"
        />

        <button
          onClick={submit}
          className="cursor-pointer text-white font-bold absolute right-4 top-4"
        >
          {status === "sending" ? (
            <CircularProgress sx={{ color: "#fff" }} size={30} />
          ) : (
            <div
              className={`${
                !IsEmailValid(email) ? "opacity-70" : "opacity-100"
              } transition duration-500 ease-in-out`}
            >
              subscribe
            </div>
          )}
        </button>

        {status === "error" && (
          <div
            className="text-rose-500"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

        {status === "success" && (
          <div
            className="text-white"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    );
  };

  return (
    <footer
      className={`bg-primary-black w-full relative z-10 ${
        pathname === "/features" && "rounded-tl-[150px]"
      }`}
    >
      {pathname !== "/contact" && (
        <div className="m-auto flex flex-col items-center pt-[140px] sm:pt-[240px] pb-[61px]">
          <div className="grid grid-cols-1 text-white text-5xl lg:text-8xl p-5 w-full sm:w-[52%] mb-[80px]">
            <span className="place-self-end">Start managing</span>
            <span className="place-self-start"> your properties</span>
            <span className="place-self-end"> the right way.</span>
          </div>

          <BookDemoButton text="Book a free demo" />

          <div className="text-primary-gray flex flex-col md:flex-row gap-4 p-2 mt-6">
            {[
              "When it suits you best",
              "100% free",
              "Tailored to your situation",
            ].map((_) => (
              <div key={_} className="flex items-center gap-2">
                <img alt={_} src={confirmIcon} />
                <div className="text-xs md:text-[15px]">{_}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="h-[1px] bg-primary-gray/50 w-full"></div>

      <div
        className={`flex flex-col sm:flex-row justify-between items-center gap-12 sm:gap-0 sm:items-start w-full sm:w-[80%] m-auto ${
          pathname === "/contact" ? "mt-[200px]" : "mt-32"
        }`}
      >
        <NavLink to="/">
          <img alt="locus logo" src={locusLogo} />
        </NavLink>

        <div className="flex flex-col gap-4 w-full sm:w-[40%]">
          <span className="px-[48px] sm:px-0 text-white font-bold text-center sm:text-left">
            Stay up to date with news and updates about Locus
          </span>
          <div className="relative w-[90%] m-auto sm:m-0 sm:w-full">
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
          </div>

          <span className="text-xs text-primary-gray text-center sm:text-left">
            By subscribing to our newsletter you agree to our{" "}
            <u className="cursor-pointer"><a
              className="underline"
              target="_blank"
              href="https://legal.locus.eu/LOCUS-PrivacyPolicy.pdf"
            >
              privacy policy
            </a></u> and will get
            commercial communication.
          </span>
        </div>
      </div>

      <div className="mt-32 h-[1px] bg-primary-gray/50 w-full"></div>

      <div className="grid grid-cols-1 sm:grid-cols-2 items-center w-full sm:w-[80%] m-auto py-[20px]">
        <div className="flex justify-center sm:justify-start gap-4">
          <a target="_blank" href="https://www.linkedin.com/company/locus-eu/">
            <img alt="linkedin icon" className="cursor-pointer" src={linkedin} />
          </a>
          {/* <img className="cursor-pointer" src={facebook} /> */}
          {/* <img className="cursor-pointer" src={twitter} /> */}
        </div>

        <div className="text-primary-gray text-[12px] flex gap-[10px] py-4 justify-center">
          <span className="cursor-pointer"> &copy; 2023 Locus</span>
          <span className="cursor-pointer">
            <a
              className="underline"
              target="_blank"
              href="https://legal.locus.eu/LOCUS-PrivacyPolicy.pdf"
            >
              Privacy Policy
            </a></span>
          <span className="cursor-pointer">
            <a
              className="underline"
              target="_blank"
              href="https://legal.locus.eu/LOCUS-CookieDeclaration.pdf"
            >
              Cookie Policy
            </a></span>
          <span className="cursor-pointer">Made by SKINN</span>
        </div>
      </div>
    </footer>
  );
};
