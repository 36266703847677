import React from "react";
import { Link } from "react-router-dom";
import arrowUp from "assets/arrow-up.svg";

export const HowItWorks = ({ elementRef }) => {
  return (
    <div
      className="bg-primary-black pt-[209px] sm:pt-[300px] pb-[96px] sm:pb-[213px] pl-[45px] sm:pl-[140px] rounded-br-[75px] sm:rounded-br-[150px] relative z-10"
    >
      <div id="How it works" ref={elementRef} className="h-[100px]"></div>
      <div className="text-white rounded-2xl bg-primary-gray/50 text-xs sm:text-sm py-1 px-5 w-fit">
        HOW IT WORKS
      </div>
      <div className="mt-[24px] text-5xl max-w-lg text-white">
        Manage your real estate in three easy steps.
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-[25px] lg:gap-4 mt-[96px]">
        <div className="flex flex-col gap-4">
          <div className="text-primary-red text-8xl">1</div>
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-white">Book a demo</span>
            <span className="text-light-gray max-w-xs">
              During a first acquaintance, we discover who you are,
              what you do and more about your needs and wishes.
            </span>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="text-primary-red text-8xl">2</div>
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-white">Start onboarding</span>
            <span className="text-light-gray max-w-xs">
              Is Locus exactly what you’re looking for? Then we’ll help you
              in inputting your properties. In doing so, we align Locus entirely to your situation.
            </span>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="text-primary-red text-8xl">3</div>
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-white">Contact us</span>
            <span className="text-light-gray max-w-xs">
              After implementation, we are at your service with answers and
              solutions.
            </span>
          </div>
        </div>
      </div>

      <div className="w-fit mt-10">
        <Link to="/contact">
          <div className="animated-button z-10 flex justify-center items-center gap-2 cursor-pointer bg-primary-red rounded-sm px-[20px] py-[13px] text-white">
            <span className="flex itme-center bg-white p-1 rounded-full">
              <img src={arrowUp} />
            </span>
            <span className="font-bold">Book a Demo</span>
          </div>
        </Link>
      </div>
    </div>
  );
};
