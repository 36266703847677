import React from "react";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { BookDemoButton } from "components";

import locusLogo from "assets/locus-logo.svg";
import closeIcon from "assets/close-icon.svg";
import linkedInBlack from "assets/linkedin-black.svg";

export const MobileNavigation = ({ isMenuOpen, handleMenuToggle }) => {
  const navItems = [
    { name: "Features", route: "/features" },
    { name: "About us", route: "/about-us" },
    { name: "Contact", route: "/contact" },
    { name: "Pricing", route: "/pricing" },
  ];

  return (
    <div
      className={`${
        isMenuOpen ? "menu-open h-screen py-[21px]" : "menu-closed h-0"
      } overflow-hidden ease-in duration-500 flex flex-col sm:hidden bg-light-gray`}
    >
      <div className="px-[21px] flex justify-between">
        <NavLink onClick={handleMenuToggle} to="/">
          <img src={locusLogo} className="w-[110px] h-[24px]" alt="locus logo" />
        </NavLink>
        <img alt="menu-icon" onClick={handleMenuToggle} src={closeIcon} />
      </div>

      <div className="text-5xl flex flex-col m-auto items-center gap-4">
        {navItems.map((_) => (
          <NavLink key={_.route} onClick={handleMenuToggle} to={_.route}>
            {_.name}
          </NavLink>
        ))}

        <NavHashLink
          smooth
          onClick={handleMenuToggle}
          to="/features#FAQ"
        >
          FAQ
        </NavHashLink>
      </div>

      <div className="flex gap-[30px] justify-center">
        <a target="_blank" href="https://www.linkedin.com/company/locus-eu/">
          <img src={linkedInBlack} />
        </a>
      </div>

      <div className="px-[21px] flex flex-col items-center gap-2 mt-auto">
        <div className="w-full font-semibold rounded-sm border border-[#cfccc6] rounded-sm p-3 text-center text-prrimary-black bg-light-gray">
          Get the app
        </div>
        <div className="w-full">
          <BookDemoButton />
        </div>
      </div>
    </div>
  );
};
