import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const FAQ = ({ elementRef }) => {
  const FAQContent = [
    {
      id: 1,
      question: "Why choose Locus? ",
      answer:
        "With Locus you manage all aspects of your real-estate portfolio more easily, more efficiently and in a centralised manner. Using the web application, you have a clear overview of all your properties and you analyse the required data. With the app, you do quick activities and communicate with your tenants. In this way, Locus reduces the worries and aims for a higher return.  ",
    },
    {
      id: 2,
      question: "Who can use Locus? ",
      answer:
        "Whether your involvement in real estate is private or professional: Locus makes property management, asset management and portfolio management easier. For both residential and commercial real estate. Also, you work efficiently with other parties, such as caretakers, lawyers or accountants. They only have access to the relevant modules. ",
    },
    {
      id: 3,
      question: "Do you need a minimum number of properties to benefit? ",
      answer: "No, Locus is available to anyone who manages real estate. ",
    },
    {
      id: 4,
      question: "In which countries does Locus operate? ",
      answer:
        "Locus is currently focusing on the Benelux, but is open to expansion. Contact us for the opportunities. ",
    },
    {
      id: 5,
      question: "Why does Locus add value for the tenant? ",
      answer:
        "Your tenants can find their residential documents all in one place. It is also where they upload the necessary certificates and insurance policies, and communicate defects or problems to you or your caretaker. Thanks to the automatic reminders they also pay their monthly rent on time. ",
    },
    {
      id: 6,
      question: "How is your data protected? ",
      answer: (
        <div className="flex flex-col gap-10">
          <div>
            Locus has been designed with the utmost attention to digital
            protection and data security. There are therefore several
            authentication layers. We carefully verify the identity of new users
            at several levels and afterwards we operate with two-factor
            authentication (2FA). In this procedure, the user can choose between
            an account password, a one-off password by e-mail or text message,
            or a fingerprint or FaceID. This method guarantees a security level
            of 99%.
          </div>
          <div>
            We also pay great attention to the protection of your data. This is
            why we choose to limit the gathering of sensitive data and we
            install several security layers. Your details are further protected
            by the fact they are saved behind firewalls and private networks. In
            addition, sensitive data is coded in our databases. Other data, such
            as bank transactions, is not saved internally. Locus is committed to
            prioritising data security at all times, and is therefore always on
            the look out and alert for updates and potential threats.
          </div>
          <div>
            For more information on the collection and use of personal data we
            refer you to our privacy policy and terms and conditions of use. We
            only gather your data for your personal experience and to manage
            your real-estate portfolio. It is and remains your data at all
            times, and is treated confidentially and correctly.
          </div>
          <div>
            Read our{" "}
            <a
              className="underline"
              target="_blank"
              href="https://legal.locus.eu/LOCUS-PrivacyPolicy.pdf"
            >
              privacy policy
            </a>
            and{" "}
            <a target="_blank" href="https://legal.locus.eu/LOCUS-Generalterms.pdf">
              <u>terms and conditions of use.</u>
            </a>
          </div>
        </div>
      ),
    },
    {
      id: 7,
      question: "How can I become a customer? ",
      answer: (
        <div>
          You book a demo during which we listen to your specific situation and
          explain exactly how Locus works. Do you have any questions? Don’t
          hesitate to send us a mail on{" "}
          <a className="underline" href="mailto:info@locus.eu">
            info@locus.eu
          </a>
          .
        </div>
      ),
    },
  ];
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div
      className="pl-[45px] sm:pl-[140px] py-[50px] rounded-br-[150px] relative z-10"
    >
      <div id="FAQ" ref={elementRef} className="h-[100px]"></div>
      <span className="bg-[#fff] font-semibold py-1 px-4 text-sm w-fit rounded-2xl">
        FAQ
      </span>
      <div className="mb-[80px] mt-[16px] text-3xl sm:text-6xl max-w-2xl">
        Optimize property management with Locus!
      </div>

      {FAQContent.map((_) => (
        <Accordion
          key={_.id}
          expanded={expanded === _.id}
          onChange={handleChange(_.id)}
          sx={{
            width: "90%",
            padding: "10px 0 10px 0",
            background: "#F6F6F6",
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={expanded === _.id ? <div className="bg-[#ff0000] w-4 h-[2px]"></div> : <AddIcon />  }>
            <div className="flex items-center gap-2">
              {expanded === _.id && (
                <div className="hidden sm:block h-[7px] w-[7px] bg-primary-red rounded-full"></div>
              )}
              <div className="text-xl sm:text-3xl">{_.question}</div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>{_.answer}</div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
