import React from "react";
import Rellax from "rellax";
import { BookDemoButton, Testimonials, Features, ForWho, PageMetaSetter } from "components";

import appPrev1 from "assets/app-prev-1.svg";
import appPrev2 from "assets/app-prev-2.svg";
import appPrev3 from "assets/app-prev-3.svg";
import scrollIndicator from "assets/scroll-indicator.png";

export const Home = () => {
  const rellaxRef = React.useRef(null);

  const options = {
    speed: -4,
    center: true,
    wrapper: null,
    round: true,
    vertical: true,
    horizontal: false,
  };

  React.useEffect(() => {
    new Rellax(rellaxRef.current, options);
  });

  return (
    <div>
      <PageMetaSetter 
        title="Locus – Easy real-estate management" 
        name="description"
        content="Locus makes managing your real-estate portfolio more efficient, clearer and professional. Discover the advantages of our platform."
      />

      <div
        ref={rellaxRef}
        className="video-container z-10 relative w-full h-screen relative flex flex-col items-center justify-center"
      >
        <video
          className="back-video bg-[#c1c1c1]"
          autoPlay
          playsInline
          muted
          loop
        >
          <source
            src="https://dgsgd7ebac00b.cloudfront.net/locus-informative-video"
            type="video/mp4"
          ></source>
        </video>

        <h1 className="z-10 grid grid-cols-1 text-white p-4 sm:p-0 text-3xl sm:text-6xl w-full sm:w-[55%] mb-[80px]">
          <span className="place-self-start">Streamline processes</span>
          <span className="place-self-center">with AI-driven property</span>
          <span className="place-self-end"> management software</span>
        </h1>
        <BookDemoButton />

        <img
          src={scrollIndicator}
          className="absolute right-12 top-[370px] z-0 sm:z-30"
          alt="scroll indicator icon"
        />
      </div>

      <div className="bg-[#0000000D]">
        <Features />

        <div className="w-[96%] rounded-bl-[150px] bg-white ml-auto">
          <div 
            onContextMenu={e => e.preventDefault()}
            role="img" 
            aria-label="Suzan using LOCUS to verify rent payments for strategic investment decisions"
            className="property-banner rounded-tr-[75px] rounded-bl-[75px] sm:rounded-tr-[150px] sm:rounded-bl-[150px] px-[25px] sm:px-[71px] py-[60px] sm:py-[116px]">
            <div className="text-white rounded-2xl bg-primary-gray/50 text-xs sm:text-sm py-1 px-3 w-fit">
              PEACE OF MIND
            </div>
            <div className="mt-8 text-4xl lg:text-6xl text-white w-full lg:w-[50%]">
              Locus cares if the rent is paid so that you can think about your next investment.
            </div>
            <div className="flex flex-col items-start sm:items-end gap-[20px] mt-[20px] mt-[62px] sm:mt-0 w-full lg:w-[70%]">
              <img loading="lazy" alt="" src={appPrev1} />
              <img loading="lazy" alt="" src={appPrev2} />
              <img loading="lazy" alt="" src={appPrev3} />
            </div>
          </div>
        </div>

        <ForWho />
        <Testimonials />
      </div>
    </div>
  );
};
