import React from 'react'
import { Routes, Route } from "react-router-dom"
import {Home, Contact, AboutUs, Features, Landing} from 'pages'

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={ <Home /> } />
      <Route path="/features" element={ <Features /> } />
      <Route path="/about-us" element={ <AboutUs /> } />
      <Route path="/contact" element={ <Contact /> } />
      <Route path="/propertymanagement" element={ <Landing /> } />
    </Routes>
  )
}

export default AppRoutes