import { Link } from 'react-router-dom'
import featureLogo1 from "assets/feature-1.svg";
import featureLogo2 from "assets/feature-2.svg";
import featureLogo3 from "assets/feature-3.svg";
import arrowUp from 'assets/arrow-up.svg'

export const Features = () => {
  return (
    <div className="bg-white relative z-20 w-[96%] ml-auto mt-[-100px] rounded-tl-[75px] sm:rounded-tl-[150px] px-[25px] sm:px-[70px] py-[50px] sm:py-[90px]">
      <div className="w-full lg:w-[85%]">
        <div className="flex flex-col gap-4 mt-6 sm:mt-10">
          <span className="bg-light-gray py-1 px-4 text-sm w-fit rounded-2xl">
            FEATURES
          </span>
          <span className="text-3xl sm:text-5xl w-full lg:w-[55%]">
            Manage your real estate easily and use the time to do what really matters
          </span>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-4 mt-14 md:mt-[128px]">
          <div className="flex flex-col gap-4">
          <img alt="Simple and accessible icon" className="w-[38px] h-[40px]" src={featureLogo1} />
            <div className="flex flex-col gap-1">
              <span className="font-semibold">Simple and accessible.</span>
              <span className="text-primary-grayw-full sm:w-[80%]">
                Locus guarantees no-nonsense real-estate management
                for efficient collaboration and clear communication.
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <img alt="Optimise your investment icon" className="w-[38px] h-[40px]" src={featureLogo2} />
            <div className="flex flex-col gap-1">
              <span className="font-semibold">Optimise your investment.</span>
              <span className="text-primary-grayw-full sm:w-[80%]">
                Using a handy platform, you monitor your properties at a glance.
                You can, therefore, target your investments in real estate.
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <img alt="all-in-one platform icon" className="w-[38px] h-[40px]" src={featureLogo3} />
            <div className="flex flex-col gap-1">
              <span className="font-semibold">All-in-one platform.</span>
              <span className="text-primary-grayw-full sm:w-[80%]">
                With Locus, you communicate, manage and centralize your real
                estate in a professional and automated manner.
              </span>
            </div>
          </div>
        </div>

        <Link to="/features" className="animated-button flex items-center gap-3 text-white rounded-sm bg-primary-red cursor-pointer mt-8 px-4 py-3 w-fit">
          <span className="flex itme-center bg-white p-1 rounded-full">
            <img alt="" src={arrowUp} />
          </span>
          <span className='font-bold'>See all opportunities</span>
        </Link>
      </div>
    </div>
  );
};
