import React from "react";
import { NavHashLink } from "react-router-hash-link";
import { NavLink, useLocation } from "react-router-dom";
import { BookDemoButton } from "components";

import locusLogo from "assets/locus-logo.svg";
import hamburgerMenuBlack from "assets/hamburger-menu-black.svg";

export const MainHeader = ({ handleMenuToggle }) => {
  const navRoutes = [
    { name: "Features", route: "/features" },
    { name: "About us", route: "/about-us" },
    { name: "Contact", route: "/contact" },
  ];

  const [lastScrollY, setLastScrollY] = React.useState(0);
  const [scrolled, setIsScrolled] = React.useState(false);
  const { pathname } = useLocation();

  window.onscroll = () => {
    if (window.scrollY > 100) setIsScrolled(true);
    else setIsScrolled(false);
  };

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setIsScrolled(false);
      } else if (window.scrollY !== 0) {
        // if scroll up show the navbar
        setIsScrolled(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  // ${!scrolled ? 'absolute' : ''}
  return (
    <div
      className={`
      ${window.scrollY === 0 ? "bg-transparent" : "bg-white shadow-md"}
      fixed 
      z-40 ease-in duration-300 px-[20px] py-[20px] sm:py-[10px] w-full flex items-center justify-between`}
    >
      <NavLink to="/">
        <img src={locusLogo} className="w-[110px] h-[24px]" alt="locus logo" />
      </NavLink>
      <img
        onClick={handleMenuToggle}
        className="block sm:hidden"
        src={hamburgerMenuBlack}
      />

      <div className="hidden sm:flex gap-[40px]">
        {navRoutes.map((nav) => (
          <NavLink
            key={nav.route}
            to={nav.route}
            className={`${
              window.scrollY === 0 && pathname === "/"
                ? "text-white"
                : "text-black"
            } ${
              pathname === nav.route && "border-b-2 border-primary-red"
            } cursor-pointer py-2`}
          >
            {nav.name}
          </NavLink>
        ))}
        
        <NavHashLink
          // smooth
          to="/features#FAQ"
          className={`${
            window.scrollY === 0 && pathname === "/"
              ? "text-white"
              : "text-black"
          } ${
            pathname === "features" && "border-b-2 border-primary-red"
          } cursor-pointer py-2`}
        >
          FAQ
        </NavHashLink>
      </div>

      <div className="hidden sm:flex items-center gap-6">
        <div
          className={`cursor-pointer ${
            window.scrollY === 0 && pathname === "/"
              ? "text-white"
              : "text-black"
          }`}
        >
          <a href="https://app.locus.eu">Log in</a>
        </div>
        <BookDemoButton text="Book a Demo" />
      </div>
    </div>
  );
};
