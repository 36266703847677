import {InlineWidget, useCalendlyEventListener} from "react-calendly";
import {useFormikContext} from "formik";

export const Step3 = ( { setIsDone } ) => {
  const { values } = useFormikContext();

  useCalendlyEventListener({
    onEventScheduled: (e) => setIsDone(true)
  });
  return (
    <div>
      <InlineWidget
        pageSettings={{
          primaryColor: '#FF0000',
        }}
        prefill={{
          email: values.email,
          firstName: values.first_name,
          lastName: values.last_name,
          name: values.first_name + ' ' + values.last_name,
          guests:[] ,
          date: new Date(Date.now() + 86400000)
        }}
        url="https://calendly.com/info-57097/30min"
      />
    </div>
  );
};
