import React from "react";
import emailjs from "@emailjs/browser";
import { Formik, Form, ErrorMessage } from "formik";
import { PopupModal } from "react-calendly";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import * as yup from "yup";

import { PageMetaSetter, Input, Checkbox } from "components";
import { FormGroup, CircularProgress, Snackbar } from "@mui/material";
import arrowUp from "assets/arrow-up.svg";
import redDot from "assets/red-dot.svg";

export const Contact = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const validationSchema = yup.object({
    from_name: yup.string().required("Full name field is required"),
    reply_to: yup
      .string()
      .email("Must be a valid email address")
      .required("Email field is required"),
    message: yup.string().required("Message field is required"),
  });

  const contactForm = React.useRef(null);
  const [isSending, setIsSending] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const CustomCheckbox = ({ status, message, onValidated, isSubmitting, email }) => {
    const [isSubscribed, setIsSubscribed] = React.useState(false);

    React.useEffect(() => {
      isSubmitting && isSubscribed &&
        onValidated({
          EMAIL: email,
        });
    }, [isSubmitting])

    return (
      <FormGroup>
        <Checkbox
          label="Yes, I want to receive the newsletter."
          name="newsLetter"
          checked={isSubscribed}
          onChange={e => setIsSubscribed(e.target.checked)}
        />

        {status === "error" && (
          <div
            className="text-rose-500"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

        {status === "success" && (
          <div
            className="text-black"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </FormGroup>
    );
  };

  return (
    <div className="bg-light-gray">
      <PageMetaSetter 
        title="Also keen to get started with simple real-estate management?"
        name="description"
        content="With Locus, you centralise your real-estate management in one clear platform. Do you have a question? Would you like more information? Book your demo or contact us."
      />
      
      <div className="pt-[100px] px-[20px] lg:px-[120px] w-[95%] m-auto">
        <h1 className="text-prrimary-black grid grid-cols-1 text-5xl lg:text-8xl w-full lg:w-[60%]">
          Contact Us
        </h1>

        <div className="text-primary-gray w-full lg:w-1/2 mt-12 px-10">
          Do you have a question? Want to book a demo? Would you like to talk to
          someone from Locus about your real-estate management? Contact us
        </div>

        <div className="flex flex-col sm:flex-row gap-4 mt-10">
          <div className="flex flex-col justify-between w-fit sm:w-full gap-8 bg-white hover:bg-primary-red hover:text-white ease-in duration-300 cursor-pointer w-full min-h-[200px] rounded-sm p-[24px]">
            <div>
              <div className="flex gap-2 items-center">
                <img alt="" className="w-[10px]" src={redDot} />
                <div className="text-xl">Call us</div>
              </div>
              <div className="flex flex-col pl-5">
                <a href="tel:+32 475 46 92 27" className="text-3xl">
                  +32 475 46 92 27 Or
                </a>
                <span className="text-3xl"></span>
                <a href="tel:+31 6 22783814" className="text-3xl">
                  +31 6 22783814
                </a>
              </div>
            </div>
            <div className="text-xl pl-5">More questions? Call us anytime!</div>
          </div>

          <div
            onClick={() => setIsOpen(true)}
            className="flex flex-col justify-between gap-8 bg-white hover:bg-primary-red hover:text-white ease-in duration-300 cursor-pointer w-full sm:w-full min-h-[200px] rounded-sm p-[24px]"
          >
            <div>
              <div className="flex gap-2 items-center">
                <img alt="" className="w-[10px]" src={redDot} />
                <div className="text-3xl">Book a demo </div>
              </div>
              <div className="text-3xl pl-5">for your specific situation. </div>
            </div>

            <div className="text-xl pl-5">
              Schedule a live demo when it suits you best.
            </div>
          </div>
        </div>
      </div>

      <div className="w-[95%] mb-[-100px] z-20 relative bg-white rounded-tr-[100px] rounded-bl-[100px] mt-[64px] lg:mt-[160px] pl-[40px] lg:pl-[336px] pr-[24px] lg:pr-[281px] py-[96px] lg:py-[116px]">
        <div className="text-3xl">Contact us today</div>
        <div className="mt-[16px] text-[18px] text-primary-gray">
          Please fill out the form. We will get back to you as quickly as we
          can, usually within one business day.
        </div>

        <Snackbar
          open={isSuccess}
          autoHideDuration={6000}
          onClose={() => setIsSuccess(false)}
          message="Email Sent!"
        />

        <Formik
          initialValues={{
            from_name: "",
            reply_to: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            setIsSending(true);
            emailjs
              .sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EAILJS_TEPLATE_ID,
                contactForm.current,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
              )
              .then(
                () => {
                  setIsSuccess(true);
                  resetForm();
                },
                (error) => console.log(error.text)
              )
              .finally(() => setIsSending(false));
          }}
        >
          {({ errors, touched, values, handleChange, isSubmitting }) => (
            <Form ref={contactForm}>
              <div className="flex flex-col gap-4 mt-[32px]">
                <div className="flex flex-col gap-[16px]">
                  <div className="flex flex-col sm:flex-row gap-4">
                    <div className="w-full">
                      <Input
                        name="from_name"
                        label="Full Name"
                        value={values.from_name}
                        onChange={handleChange}
                        error={errors.from_name && touched.from_name}
                      />
                      <ErrorMessage
                        render={(msg) => (
                          <span className="text-primary-red">{msg}</span>
                        )}
                        name="from_name"
                      />
                    </div>

                    <div className="w-full">
                      <Input
                        name="reply_to"
                        label="Email"
                        value={values.reply_to}
                        onChange={handleChange}
                        error={errors.from_name && touched.from_name}
                      />
                      <ErrorMessage
                        render={(msg) => (
                          <span className="text-primary-red">{msg}</span>
                        )}
                        name="reply_to"
                      />
                    </div>
                  </div>

                  <div>
                    <Input
                      name="message"
                      label="Your message"
                      value={values.message}
                      onChange={handleChange}
                      multiline
                      error={errors.message && touched.message}
                    />
                    <ErrorMessage
                      render={(msg) => (
                        <span className="text-primary-red">{msg}</span>
                      )}
                      name="message"
                    />
                  </div>
                </div>

                <MailchimpSubscribe
                  url={process.env.REACT_APP_MAILCHIMP_URL}
                  render={({ subscribe, status, message }) => (
                    <CustomCheckbox
                      status={status}
                      message={message}
                      onValidated={(formData) => subscribe(formData)}
                      isSubmitting={isSubmitting}
                      email={values.reply_to}
                    />
                  )}
                />

                <button
                  type="submit"
                  className="animated-button flex items-center gap-3 cursor-pointer bg-primary-red p-[31px] text-center text-lg max-h-[57px] rounded-sm text-white w-fit"
                >
                  <span className="flex itme-center bg-white p-1 rounded-full">
                    <img alt="" src={arrowUp} />
                  </span>
                  {isSending ? (
                    <CircularProgress sx={{ color: "#fff" }} size={30} />
                  ) : (
                    <span className="font-bold">Send</span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <PopupModal
        url="https://calendly.com/info-57097/30min"
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
    </div>
  );
};
