
import TextField from '@mui/material/TextField';

export const Input = (props) => {
  return (
    <TextField
			sx={{
				border: props.error ? '1px solid primary-red' : '',
			}}
      fullWidth
      name={props.name}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      error={props.error}
			multiline={props.multiline}
			placeholder={props.placeholder}
			rows={6}
    />
  );
};
