import {ErrorMessage, Form, useFormikContext} from "formik";
import {Input} from "../input";
import React, {useEffect} from "react";

export const Step2 = ({ numberOfUnits }) => {
  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('number_of_units', numberOfUnits);
  }, []);

  return (
    <div>
      <div className="text-main-black text-center font-bold font-space-grotesk text-[20px] sm:text-[32px]">
        Tell us about you
      </div>
      <div className="flex flex-col sm:flex-row gap-[20px] mt-[14px]">
        <div className="flex w-full sm:w-1/2 flex-col items-center gap-[20px]">
          <div className="w-full flex flex-col gap-[10px]">
            <div className="text-[17px] text-[#10275B] font-[300]">First name *</div>
            <Input
              name="first_name"
              placeholder="First Name"
              value={values.first_name}
              onChange={handleChange}
              error={touched.first_name && errors.first_name}
            />
            <ErrorMessage
              name="first_name"
              render={msg => <div className="text-primary-red">{msg}</div>}
            />
          </div>
          <div className="w-full flex flex-col gap-[10px]">
            <div className="text-[17px] text-[#10275B] font-[300]">Phone number *</div>
            <Input
              name="phone_number"
              placeholder="Phone Number"
              value={values.phone_number}
              onChange={handleChange}
              error={touched.phone_number && errors.phone_number}
            />
            <ErrorMessage
              name="phone_number"
              render={msg => <div className="text-primary-red">{msg}</div>}
            />
          </div>
          <div className="w-full flex flex-col gap-[10px]">
            <div className="text-[17px] text-[#10275B] font-[300]">How many properties do you manage? *</div>
            <Input
              name="number_of_units"
              placeholder=""
              value={values.number_of_units}
              onChange={handleChange}
              error={touched.number_of_units && errors.number_of_units}
            />
            <ErrorMessage
              name="number_of_units"
              render={msg => <div className="text-primary-red">{msg}</div>}
            />
          </div>
        </div>
        <div className="flex w-full sm:w-1/2 flex-col items-center gap-[20px]">
          <div className="w-full flex flex-col gap-[10px]">
            <div className="text-[17px] text-[#10275B] font-[300]">Last name *</div>
            <Input
              name="last_name"
              placeholder="Last Name"
              value={values.last_name}
              onChange={handleChange}
              error={touched.last_name && errors.last_name}
            />
            <ErrorMessage
              name="last_name"
              render={msg => <div className="text-primary-red">{msg}</div>}
            />
          </div>
          <div className="w-full flex flex-col gap-[10px]">
            <div className="text-[17px] text-[#10275B] font-[300]">Country *</div>
            <Input
              name="country_number"
              placeholder="Country"
              value={values.country_number}
              onChange={handleChange}
              error={touched.country_number && errors.country_number}
            />
            <ErrorMessage
              name="country_number"
              render={msg => <div className="text-primary-red">{msg}</div>}
            />
          </div>
        </div>
      </div>
    </div>
  );
}