import { Helmet } from "react-helmet-async";

export const PageMetaSetter = ({ title, name, content }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name={name}
        content={content}
      />
    </Helmet>
  );
};
